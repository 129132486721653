
































































import {
  defineComponent,
  ref,
  useFetch,
  useContext,
  ssrRef,
} from '@nuxtjs/composition-api';

import {
  useBlog,
  useUiHelpers
} from '~/composables';

import {
  SfHeading,
  SfBreadcrumbs,
  SfPagination
} from '~/components';

import BlogNavbar from '../components/BlogNavbar.vue';
import PostsGrid from '../components/PostsGrid.vue';

import {
  getCategories,
  getPosts
} from '~/modules/blog/getters/blogGetters';
import { useBlogStore } from '~/modules/blog/stores/blog';

export default defineComponent({
  name: 'Blog',
  head() {
    return {
      title: this.$i18n.tc('Blog')
    };
  },
  components: {
    SfHeading,
    SfBreadcrumbs,
    SfPagination,
    BlogNavbar,
    PostsGrid,
  },
  setup() {
    const { app: { i18n }, localePath } = useContext();
    const { loadCategories, loadPosts, loadingCategories, loadingPosts } = useBlog();
    const { getFacetsFromURL, changeFilters } = useUiHelpers();
    const blogStore = useBlogStore();

    const breadcrumbs = ref([]);
    breadcrumbs.value = [
      {
        link: localePath('/'),
        text: i18n.t('Home') as string,
      },
      {
        link: '#',
        text: i18n.t('Blog') as string
      }
    ];

    const categories = ssrRef([]);
    const posts = ref([]);
    const currentFilters = ref({ category_id: [] });
    const postsTotalCount = ref();
    const currentPage = ref();

    const loadCategoriesData = async () => {
      const categoriesData = await loadCategories();
      categories.value = getCategories(categoriesData.value);
    };

    const loadPostsData = async () => {
      posts.value = [];
      const postsData = await loadPosts(getFacetsFromURL());
      posts.value = getPosts(postsData.value);
      postsTotalCount.value = postsData.value.data.blogPosts?.page_info?.total_pages || 0;
      currentPage.value = postsData.value.data.blogPosts?.page_info?.current_page || 0;
    };

    useFetch(async () => {
      if (blogStore.posts.length) {
        posts.value = blogStore.posts;
      }

      if (blogStore.categories.length) {
        categories.value = blogStore.categories;
      }

      if (!blogStore.posts.length || !blogStore.categories.length) {
        await loadCategoriesData();
        await loadPostsData();

        const filtersFromUrl = getFacetsFromURL().filters.category_id;

        if (filtersFromUrl?.length) {
          currentFilters.value.category_id.push(...filtersFromUrl);
        }

        blogStore.$patch((state) => {
          state.posts = posts.value;
          state.categories = categories.value;
        });
      }
    });

    const selectCategory = categoryId => {
      if (categoryId) {
        const isSelected = currentFilters.value['category_id']?.includes(categoryId);
        if (isSelected) {
          currentFilters.value['category_id'].splice(currentFilters.value['category_id'].indexOf(categoryId), 1);
        } else {
          currentFilters.value['category_id'].push(categoryId);
        }
        changeFilters({ category_id: currentFilters.value['category_id'] }, false);
        loadPostsData();
      } else {
        currentFilters.value['category_id'] = [];
        changeFilters({ category_id: currentFilters.value['category_id'] }, false);
        loadPostsData();
      }
    };

    return {
      breadcrumbs,
      categories,
      posts,
      selectCategory,
      loadingCategories,
      loadingPosts,
      currentFilters,
      postsTotalCount,
      currentPage,
    };
  }
});
