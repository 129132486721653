import { defineStore } from 'pinia';
import type { BlogPost, BlogCategory } from '~/modules/GraphQL/types';

interface BlogState {
  posts: BlogPost[];
  categories: BlogCategory[];
  storedPosts: BlogPost[];
}

export const useBlogStore = defineStore('blog', {
  state: (): BlogState => ({
    posts: [],
    categories: [],
    storedPosts: []
  }),
});
