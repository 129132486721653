
































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SkeletonLoader } from '~/components';
import PostItem from '../components/PostItem.vue';
import { ObserveVisibility } from 'vue-observe-visibility';
import Vue from 'vue';

Vue.directive('observe-visibility', ObserveVisibility);

export default defineComponent({
  name: 'PostsGrid',
  components: {
    PostItem,
    SkeletonLoader
  },
  props: {
    posts: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const publishedPosts = computed(() => {
      return props.posts.filter(post => post['status'] === 'Published');
    });

    const visibilityChanged = (isVisible, entry) => {
      if (isVisible) {
        entry.target.classList.remove('slide-fade');
      }
    };

    return {
      publishedPosts,
      visibilityChanged,
    };
  }
});
