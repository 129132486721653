


































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SkeletonLoader } from '~/components';

export default defineComponent({
  name: "BlogNavbar",
  components: {
    SfButton,
    SkeletonLoader
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    currentFilters: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const enabledCategories = computed(() => {
      return props.categories.filter(category => category['status'] === 'Enabled')
    });

    return {
      enabledCategories
    }
  }
})
