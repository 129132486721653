import { useContext } from '@nuxtjs/composition-api';

export const getCategories = categoriesData => (categoriesData.data?.blogCategories ? categoriesData.data.blogCategories.items : []);

export const getPosts = postsData => (postsData.data.blogPosts?.items || []);

export const getPost = postData => (postData.data.blogPosts?.items[0] || {});

export const getRelatedPosts = postData => (postData.data.blogPosts?.items[0].related_posts
  .filter(post => post['status'] === 'Published') || []);

export const getRelatedProducts = postData => (postData.data.blogPosts?.items[0]?.related_products || null);

export const getId = post => (post && post.post_id ? post.post_id : '');

export const getPostImage = post => (post && post.post_thumbnail ? post.post_thumbnail : '');

export const getListImage = post => (post && post.list_thumbnail ? post.list_thumbnail : '');

export const getDate = post => {
  if (!post) return '';

  const { app } = useContext();

  const date = new Date(post.published_at);
  return date.toLocaleDateString(app.i18n.localeProperties.iso, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getTitle = post => (post && post.title ? post.title : '');

export const getShortContent = post => (post && post.short_content ? post.short_content : '');

export const getFullContent = post => (post && post.full_content ? post.full_content : '');

export const getUrl = post => (post && post.url_key ? post.url_key : '');

const blogGetters = {
  getPosts,
  getCategories,
  getPost,
  getRelatedPosts,
  getRelatedProducts,
  getId,
  getPostImage,
  getListImage,
  getDate,
  getTitle,
  getShortContent,
  getFullContent,
  getUrl
};

export default blogGetters;
