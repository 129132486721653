





























import {
  defineComponent,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';
import { SfImage } from '~/components';
import blogGetters from '~/modules/blog/getters/blogGetters';
import placeholder from '~/styles/images/transparent_placeholder.svg';

export default defineComponent({
  name: 'PostItem',
  components: {
    SfImage,
  },
  props: {
    post: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const { app } = useContext();
    const router = useRouter();

    const handleClick = postUrl => {
      router.push(decodeURIComponent(app.localePath(`/blog/${postUrl}`)));
    };

    return {
      postGetters: blogGetters,
      placeholder,
      handleClick
    };
  }

});
